import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { api } from '../auth/auth';
import DatasetCard from './DatasetCard';
import CSVUpload from './CSVUpload';
import { FiPlus } from 'react-icons/fi'; // Icons for better visuals

function DatasetsPage() {
  const [datasets, setDatasets] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const navigate = useNavigate(); // Initialize useNavigate

  const fetchDatasets = async () => {
    try {
      setIsLoading(true); // Start loading
      const response = await api.get('/api_bingo/datasets/');
      setDatasets(response.data.datasets);
    } catch (error) {
      console.error('Error fetching datasets:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchDatasets();
  }, []);

  const handleUploadSuccess = () => {
    fetchDatasets();
  };

  const handleDeleteDataset = async (fileName) => {
    if (window.confirm('Are you sure you want to delete this dataset?')) {
      try {
        await api.delete(`/api_bingo/datasets/${encodeURIComponent(fileName)}/`);
        fetchDatasets();
      } catch (error) {
        console.error('Error deleting dataset:', error);
      }
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Manage Datasets</h1>

      {/* Create and Upload buttons */}
      <div className="mb-6 flex flex-col space-y-4">
        <button
          className="bg-green-600 text-white py-2 px-4 rounded-lg flex items-center space-x-2 hover:bg-green-500 transition max-w-52"
          onClick={() => navigate('/create-dataset')}
        >
          <FiPlus size={20} />
          <span>Create Dataset</span>
        </button>

        <CSVUpload onUploadSuccess={handleUploadSuccess} />
      </div>

      {/* Loading state */}
      {isLoading ? (
        <div className="flex justify-center items-center py-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
        </div>
      ) : datasets.length === 0 ? (
        // Empty state
        <div className="text-center text-gray-500">
          <p>No datasets available. Start by creating or uploading a new dataset!</p>
        </div>
      ) : (
        // Dataset cards grid
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {datasets.map((dataset) => (
            <DatasetCard
              key={dataset.file_name}
              dataset={dataset}
              onDelete={handleDeleteDataset}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default DatasetsPage;
