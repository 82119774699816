// src/components/MainScreen.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../auth/auth';

function MainScreen() {
  const navigate = useNavigate();
  const isLoggedIn = isAuthenticated();

  const [language, setLanguage] = useState('pl'); // State for managing language

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate]);

  // Function to toggle between English and Polish
  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'pl' ? 'en' : 'pl'));
  };

  // Text in English and Polish
  const content = {
    pl: {
      title: "Witamy w naszej aplikacji edukacyjnej",
      subtitle: "Nauka w lepszym wydaniu!",
      description: "Dołącz do nas, aby uczynić naukę zabawną i interaktywną. Nauczyciele mogą zarządzać zasobami, a uczniowie mogą angażować się w edukacyjne gry.",
      login: "Zaloguj się",
      switchLanguage: "English",
    },
    en: {
      title: "Welcome to Our Learning App",
      subtitle: "Enhance Your Learning Experience",
      description: "Join us to make learning fun and interactive. Teachers can manage resources, and students can engage with educational games.",
      login: "Login",
      switchLanguage: "Polski",
    },
  };

  const { title, subtitle, description, login, switchLanguage } = content[language];

  return (
<div className="relative flex min-h-screen flex-col items-center bg-blue-900">
  {/* Hero Section */}
  <div
    className="w-full h-screen sm:h-[90vh] bg-cover bg-center flex items-center justify-center"
    style={{ backgroundImage: "url('/bingo-hero.webp')" }}
  >
    <div className="h-full w-full bg-blue-900 bg-opacity-30 flex flex-col justify-end md:justify-end items-start p-4 sm:p-6">
      {/* Language Switcher */}
      <div className="absolute top-4 right-4 sm:top-8 sm:left-6">
        <button
          onClick={toggleLanguage}
          className="bg-white text-blue-900 px-3 py-1.5 sm:px-4 sm:py-2 rounded-lg font-semibold hover:bg-gray-200 transition duration-300 shadow-lg"
        >
          {switchLanguage}
        </button>
      </div>
      {/* Content */}
      <div className="text-center text-white bg-blue-900 bg-opacity-60 rounded-lg p-4 sm:p-6 max-w-xs sm:max-w-lg">
        
        <h2 className="text-xl sm:text-3xl font-medium mb-3 sm:mb-4">{subtitle}</h2>
        <p className="text-base sm:text-lg font-light leading-relaxed mb-4 sm:mb-6">{description}</p>
        <Link
          to="/login"
          className="bg-yellow-500 text-blue-900 px-4 sm:px-6 py-2 sm:py-3 rounded-lg font-semibold hover:bg-yellow-400 transition duration-300 shadow-lg"
        >
          {login}
        </Link>
      </div>
    </div>
  </div>
</div>

  );
}

export default MainScreen;
