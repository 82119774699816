// DropArea.js
import React from 'react';
import { useDrop } from 'react-dnd';

const ItemTypes = {
  SHAPE: 'shape',
};

const DropArea = ({ onDrop, isDroppable, disabled }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ItemTypes.SHAPE,
    drop: (item) => onDrop(item),
    canDrop: (item) => !disabled && isDroppable(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const getBackgroundColor = () => {
    if (isOver) {
      return canDrop ? 'bg-green-300' : 'bg-red-300';
    }
    return 'bg-blue-100';
  };

  const getBorderColor = () => {
    if (isOver) {
      return canDrop ? 'border-green-500' : 'border-red-500';
    }
    return 'border-gray-400';
  };

  return (
    <div
      ref={drop}
      className={`w-64 h-64 flex items-center justify-center transition-all duration-300 ${getBackgroundColor()} rounded-xl shadow-lg border-4 ${getBorderColor()}`}
    >
      {isOver ? (canDrop ? 'Drop here!' : 'Cannot drop here!') : 'Drag the correct shape'}
    </div>
  );
};

export default DropArea;
