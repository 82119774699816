// src/components/SentenceGame.js

import React, { useState, useEffect, useCallback } from 'react';
import { api } from '../auth/auth'; // Ensure your API instance includes authentication headers
import { FaCheckCircle, FaTimesCircle, FaRedo, FaPlay } from 'react-icons/fa';
import ModeSelection from './ModeSelection'; // Import ModeSelection component

const SentenceGame = () => {
  const [mode, setMode] = useState(null); // 'present', 'past', 'mixed'
  const [sentences, setSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedWord, setSelectedWord] = useState('');
  const [completedSentences, setCompletedSentences] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [gameOver, setGameOver] = useState(false);
  const [timeTaken, setTimeTaken] = useState(null);
  const [bestTime, setBestTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null); // For immediate feedback
  const [incorrectWordsByCategory, setIncorrectWordsByCategory] = useState({});

  // Fetch incorrect words once when mode is selected
  const fetchIncorrectWords = useCallback(async () => {
    try {
      const response = await api.get('/words/incorrect-by-category');
      setIncorrectWordsByCategory(response.data);
    } catch (error) {
      console.error('Error fetching incorrect words:', error);
    }
  }, []);

  // Fetch sentences based on selected mode
  const fetchSentences = useCallback(async (selectedMode) => {
    setLoading(true);
    try {
      const response = await api.get('/sentences-by-tense', { params: { tense: selectedMode } });
      // Flatten sentences from all sentence sets
      const allSentences = response.data.flatMap(set => set.sentences);
      // Shuffle sentences
      const shuffledSentences = allSentences.sort(() => Math.random() - 0.5);
      setSentences(shuffledSentences);
      setStartTime(Date.now());
      setLoading(false);
      if (shuffledSentences.length > 0) {
        setCurrentSentenceIndex(0);
        setSelectedWord('');
        setCompletedSentences([]);
        generateOptions(shuffledSentences[0]);
      }
    } catch (error) {
      console.error('Error fetching sentences:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (mode) {
      fetchIncorrectWords();
      fetchSentences(mode);
    }
  }, [mode, fetchIncorrectWords, fetchSentences]);

  const generateOptions = (sentence) => {
    if (!sentence || !sentence.words || sentence.words.length === 0) {
      console.error('Invalid sentence data:', sentence);
      setOptions([]);
      return;
    }

    const missingWordObj = sentence.words.find(word => word.is_correct);
    if (!missingWordObj) {
      console.error('No correct word found in current sentence:', sentence);
      setOptions([]);
      return;
    }

    const correctWord = missingWordObj.text;
    const category = missingWordObj.category;
    const incorrectWords = incorrectWordsByCategory[category] || [];

    // Select 3 incorrect words randomly
    const selectedIncorrectWords = incorrectWords
      .sort(() => Math.random() - 0.5)
      .slice(0, 3);

    // Ensure there are enough incorrect options
    const uniqueIncorrectWords = Array.from(new Set(selectedIncorrectWords)).filter(word => word !== correctWord);

    // Combine correct word with incorrect options
    const optionsArray = [...uniqueIncorrectWords, correctWord].sort(() => Math.random() - 0.5);

    setOptions(optionsArray);
  };

  const handleWordSelect = (word) => {
    const currentSentence = sentences[currentSentenceIndex];
    const missingWordObj = currentSentence.words.find(word => word.is_correct);
    const correctWord = missingWordObj.text;

    if (word === correctWord) {
      // Correct selection
      setSelectedWord(word);
      setFeedback({ type: 'success', message: 'Correct!', icon: <FaCheckCircle className="inline text-green-500 ml-2" /> });
      const completedSentence = currentSentence.text.replace(correctWord, word);
      setCompletedSentences([...completedSentences, completedSentence]);

      // Move to next sentence after a short delay
      setTimeout(() => {
        if (currentSentenceIndex + 1 < sentences.length) {
          setCurrentSentenceIndex(currentSentenceIndex + 1);
          setSelectedWord('');
          setFeedback(null);
          generateOptions(sentences[currentSentenceIndex + 1]);
        } else {
          // Game over
          const endTime = Date.now();
          const totalTime = Math.floor((endTime - startTime) / 1000); // Time in seconds
          setTimeTaken(totalTime);
          setGameOver(true);

          // Save the best time if it's the first time or a new record
          if (!bestTime || totalTime < bestTime) {
            setBestTime(totalTime);
            saveBestTime(totalTime);
          }
        }
      }, 1000);
    } else {
      // Incorrect selection
      setFeedback({ type: 'error', message: 'Incorrect! Please try again.', icon: <FaTimesCircle className="inline text-red-500 ml-2" /> });
    }
  };

  const resetGame = () => {
    setMode(null);
    setSentences([]);
    setCurrentSentenceIndex(0);
    setOptions([]);
    setSelectedWord('');
    setCompletedSentences([]);
    setStartTime(null);
    setGameOver(false);
    setTimeTaken(null);
    setFeedback(null);
  };

  const saveBestTime = async (totalTime) => {
    try {
      const response = await api.post('/save-best-time', { time: totalTime });
      setBestTime(response.data.time);
    } catch (error) {
      console.error('Error saving best time:', error);
    }
  };

  if (!mode) {
    return <ModeSelection onSelectMode={setMode} />;
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-100 to-purple-100">
        <FaPlay className="animate-spin text-6xl text-blue-500 mr-4" />
        <span className="text-xl text-blue-600">Loading sentences...</span>
      </div>
    );
  }

  if (sentences.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-r from-red-100 to-pink-100">
        <div className="flex flex-col items-center bg-red-200 p-8 rounded-lg shadow-lg">
          <FaTimesCircle className="text-red-600 text-6xl mb-4" />
          <h2 className="text-3xl font-semibold text-red-700 mb-2">No Sentences Available</h2>
          <p className="text-center text-red-600">Please contact your teacher to add sentences.</p>
          <button
            onClick={resetGame}
            className="mt-6 flex items-center px-6 py-3 bg-red-400 text-white font-semibold rounded-md shadow hover:bg-red-500 transition duration-300"
          >
            <FaRedo className="mr-2" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (gameOver) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-green-200 to-blue-200">
        <FaCheckCircle className="text-6xl text-green-600 mb-4" />
        <h1 className="text-4xl font-bold mb-4">Congratulations!</h1>
        <p className="text-2xl mb-4">You completed the game in {timeTaken} seconds.</p>
        {bestTime && <p className="text-xl mb-6">Your best time: {bestTime} seconds</p>}
        <button
          onClick={resetGame}
          className="flex items-center px-6 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
        >
          <FaRedo className="mr-2" />
          Play Again
        </button>
      </div>
    );
  }

  const currentSentence = sentences[currentSentenceIndex];
  const missingWordObj = currentSentence.words.find(w => w.is_correct);

  if (!missingWordObj) {
    console.error('No correct word found in current sentence:', currentSentence);
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-r from-red-100 to-pink-100">
        <div className="flex flex-col items-center bg-red-200 p-8 rounded-lg shadow-lg">
          <FaTimesCircle className="text-red-600 text-6xl mb-4" />
          <h2 className="text-3xl font-semibold text-red-700 mb-2">Invalid Sentence Data</h2>
          <p className="text-center text-red-600">Please contact your teacher to fix the sentence.</p>
          <button
            onClick={resetGame}
            className="mt-6 flex items-center px-6 py-3 bg-red-400 text-white font-semibold rounded-md shadow hover:bg-red-500 transition duration-300"
          >
            <FaRedo className="mr-2" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  const sentenceWithBlank = currentSentence.text.replace(missingWordObj.text, '______');

  return (
    <div className="min-h-screen bg-gradient-to-r from-indigo-100 to-blue-200 py-8">
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mb-6 text-center text-indigo-700 flex items-center justify-center">
          <FaPlay className="mr-3 text-indigo-600" />
          Sentence Building Game
        </h1>
        <p className="text-lg mb-6 text-center text-gray-700">Select the correct verb tense to complete the sentence.</p>
        
        {/* Progress Indicator */}
        <div className="text-center mb-4">
          <span className="font-semibold text-indigo-600">Sentence:</span> {currentSentenceIndex + 1} of {sentences.length}
        </div>

        {/* Current Sentence */}
        <div className="mb-6">
          <div className="border p-4 rounded-md bg-white shadow-lg">
            <p className="text-2xl break-words text-gray-800">{sentenceWithBlank}</p>
          </div>
        </div>

        {/* Feedback Message */}
        {feedback && (
          <div className={`mb-4 p-4 rounded-md flex items-center ${feedback.type === 'success' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {feedback.icon}
            <span className="ml-2">{feedback.message}</span>
          </div>
        )}

        {/* Word Options */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleWordSelect(option)}
              className={`flex items-center justify-center px-4 py-2 bg-white text-blue-600 font-semibold rounded-md shadow hover:bg-blue-50 transition duration-300 ${
                selectedWord === option
                  ? option === missingWordObj.text
                    ? 'border-2 border-green-500'
                    : 'border-2 border-red-500'
                  : ''
              }`}
              disabled={!!selectedWord}
            >
              {option}
            </button>
          ))}
        </div>

        {/* Timer */}
        <div className="mt-8 text-lg text-center text-gray-700">
          Time Elapsed: {Math.floor((Date.now() - startTime) / 1000)} seconds
        </div>

        {/* Completed Sentences */}
        <div className="mt-8">
          <h3 className="text-2xl font-semibold mb-4 text-center text-indigo-700">Completed Sentences</h3>
          <div className="space-y-2">
            {completedSentences.map((sentence, index) => (
              <div key={index} className="border p-3 rounded-md bg-green-100 text-green-800 shadow-md">
                <p className="text-md break-words">{sentence}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentenceGame;