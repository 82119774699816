// src/components/Navbar.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FaHome, 
  FaTable, 
  FaGamepad, 
  FaThLarge, 
  FaInfoCircle, 
  FaBars, 
  FaTimes,
  FaChalkboardTeacher, // Icon for Sentence Game and Control Mode
} from 'react-icons/fa';
import { FiLogIn, FiLogOut } from 'react-icons/fi';

const Navbar = ({ token, onLogout, user }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to render admin-specific links
  const renderAdminLinks = () => (
    <>
      <Link
        to="/datasets"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaTable className="text-xl" />
        <span>Datasets</span>
      </Link>

      <Link
        to="/bingo-app"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaGamepad className="text-xl" />
        <span>Bingo App</span>
      </Link>

      <Link
        to="/card-generator"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaThLarge className="text-xl" />
        <span>Card Generator</span>
      </Link>

      <Link
        to="/info"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaInfoCircle className="text-xl" />
        <span>Info</span>
      </Link>

      <Link
        to="/control-mode"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaChalkboardTeacher className="text-xl" />
        <span>Control Mode</span>
      </Link>
    </>
  );

  // Function to render student-specific links
  const renderStudentLinks = () => (
    <>
      <Link
        to="/dashboard"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaTable className="text-xl" />
        <span>Dashboard</span>
      </Link>

      <Link
        to="/sentence-game"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaChalkboardTeacher className="text-xl" />
        <span>Sentence Game</span>
      </Link>

      <Link
        to="/flashcards"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaThLarge className="text-xl" />
        <span>Flashcards</span>
      </Link>

      <Link
        to="/dashboard"
        className="flex items-center space-x-1 hover:text-gray-200 transition"
        onClick={() => setIsMobileMenuOpen(false)}
      >
        <FaGamepad className="text-xl" />
        <span>Games</span>
      </Link>
    </>
  );

  return (
    <nav className="bg-bluey-600 p-4 text-white flex justify-between items-center relative">
      {/* Left Section: Logo and Links */}
      <div className="flex items-center space-x-4">
        {/* Logo */}
        <Link to="/" className="flex items-center space-x-2 hover:text-gray-200 transition">
          <img src="/np_small.webp" alt="Logo" className="h-10 w-auto rounded-lg" /> {/* Adjust size as needed */}
          <span>Home</span>
        </Link>

        {/* Desktop Menu Links */}
        <div className="hidden md:flex items-center space-x-4">
          {token && user && user.role === 'admin' && renderAdminLinks()}
          {token && user && user.role === 'student' && renderStudentLinks()}
        </div>
      </div>

      {/* Right Section: Login/Logout Button */}
      <div className="hidden md:block">
        {token ? (
          <button
            onClick={handleLogout}
            className="flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded transition"
          >
            <FiLogOut className="text-xl" />
            <span>Logout</span>
          </button>
        ) : (
          <Link
            to="/login"
            className="flex items-center space-x-2 bg-green-500 hover:bg-green-600 py-1 px-3 rounded transition"
          >
            <FiLogIn className="text-xl" />
            <span>Login</span>
          </Link>
        )}
      </div>

      {/* Mobile Menu Icon */}
      <div className="md:hidden flex items-center">
        <button onClick={toggleMobileMenu} className="text-2xl focus:outline-none">
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Full-Screen Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-blue-600 z-50 flex flex-col justify-center items-center text-white space-y-8">
          {/* Close the menu when clicking outside or selecting a link */}
          <Link
            to="/"
            className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
            onClick={toggleMobileMenu}
          >
            <FaHome className="text-3xl" />
            <span>Home</span>
          </Link>

          {token && user && user.role === 'admin' && renderAdminLinks()}
          {token && user && user.role === 'student' && renderStudentLinks()}

          {/* Logout/Login Button */}
          {token ? (
            <button
              onClick={handleLogout}
              className="text-2xl flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded transition"
            >
              <FiLogOut className="text-3xl" />
              <span>Logout</span>
            </button>
          ) : (
            <Link
              to="/login"
              className="text-2xl flex items-center space-x-2 bg-green-500 hover:bg-green-600 py-2 px-4 rounded transition"
              onClick={toggleMobileMenu}
            >
              <FiLogIn className="text-3xl" />
              <span>Login</span>
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
