// src/components/BingoCardGenerator.jsx

import React, { useState, useEffect } from 'react';
import { parse } from 'papaparse';
import jsPDF from 'jspdf';
import { FaDownload } from 'react-icons/fa';
import VerbFormSelector from './VerbFormSelector';
import { api } from '../auth/auth'; // Adjust the import path as needed
import { IoMdRefresh } from "react-icons/io";

function BingoCardGenerator() {
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [columns, setColumns] = useState([]);
  const [selectedForm, setSelectedForm] = useState('');
  const [dataRows, setDataRows] = useState([]);
  const [cards, setCards] = useState([]);
  const [numCards, setNumCards] = useState(4); // Default is 4 cards
  const [gridSize, setGridSize] = useState(5); // Default grid size is 5x5

  useEffect(() => {
    // Fetch available datasets when the component mounts
    const fetchDatasets = async () => {
      try {
        const response = await api.get('/api_bingo/datasets/');
        setDatasets(response.data.datasets);
      } catch (error) {
        console.error('Error fetching datasets:', error);
      }
    };

    fetchDatasets();
  }, []);

  // Load the selected dataset and extract columns
  useEffect(() => {
    if (selectedDataset) {
      const fetchDataset = async () => {
        try {
          const encodedFileName = encodeURIComponent(selectedDataset);
          const response = await api.get(`/api_bingo/datasets/${encodedFileName}/`, {
            responseType: 'blob',
          });
          const textData = await response.data.text();
          parse(textData, {
            header: true,
            complete: (result) => {
              const filteredData = result.data.filter((row) => {
                // Ensure all columns have non-empty values
                return Object.values(row).every(
                  (value) => value !== null && value !== ''
                );
              });
              setDataRows(filteredData);
              setColumns(result.meta.fields);
              setSelectedForm(''); // Reset selected form when dataset changes
            },
          });
        } catch (error) {
          console.error('Error loading dataset:', error);
        }
      };

      fetchDataset();
    } else {
      setColumns([]);
      setDataRows([]);
      setSelectedForm('');
    }
  }, [selectedDataset]);

  const generateBingoCards = () => {
    const totalCells = gridSize * gridSize;

    if (dataRows.length < totalCells) {
      alert(`Not enough words to generate a ${gridSize}x${gridSize} card.`);
      return;
    }
    if (!selectedForm) {
      alert('Please select a mode to generate bingo cards.');
      return;
    }

    // Generate cards, each card has gridSize * gridSize words
    const cardWords = Array.from({ length: numCards }, () => {
      const shuffledData = [...dataRows].sort(() => 0.5 - Math.random());
      // Take exactly totalCells words for the card
      return shuffledData
        .slice(0, totalCells)
        .map((row) => row[selectedForm])
        .filter(Boolean);
    });

    setCards(cardWords);
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
    });

    const cardsPerPage = 4;

    cards.forEach((card, cardIndex) => {
      if (cardIndex % cardsPerPage === 0 && cardIndex !== 0) {
        doc.addPage(); // Add a new page after every 4 cards
      }

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      const xPos = (cardIndex % 2) * (pageWidth / 2) + 30;
      const yPos =
        Math.floor((cardIndex % cardsPerPage) / 2) * (pageHeight / 2) + 30;
      const cardWidth = pageWidth / 2 - 60;
      const cardHeight = pageHeight / 2 - 60;

      // Draw the outer border of the card (thick)
      doc.setLineWidth(2); // Thicker outer border
      doc.rect(xPos, yPos, cardWidth, cardHeight);

      // Draw the grid inside the card (thinner)
      const cellWidth = cardWidth / gridSize;
      const cellHeight = cardHeight / gridSize;
      doc.setLineWidth(0.5); // Thinner grid lines

      for (let i = 0; i <= gridSize; i++) {
        // Horizontal lines
        doc.line(
          xPos,
          yPos + i * cellHeight,
          xPos + cardWidth,
          yPos + i * cellHeight
        );
        // Vertical lines
        doc.line(
          xPos + i * cellWidth,
          yPos,
          xPos + i * cellWidth,
          yPos + cardHeight
        );
      }

      // Draw the words inside the cells
      card.forEach((word, index) => {
        const col = index % gridSize;
        const row = Math.floor(index / gridSize);
        const wordX = xPos + col * cellWidth + cellWidth / 2; // Center the word horizontally
        const wordY = yPos + row * cellHeight + cellHeight / 2 + 5; // Center the word vertically with slight adjustment
        // Adjust font size based on grid size, reduced by 2 points
        let fontSize;
        if (gridSize === 3) {
          fontSize = word.length > 10 ? 14 : 16;
        } else if (gridSize === 4) {
          fontSize = word.length > 10 ? 12 : 14;
        } else if (gridSize === 5) {
          fontSize = word.length > 10 ? 8 : 10;
        } else if (gridSize === 6) {
          fontSize = word.length > 10 ? 6 : 8;
        } else {
          fontSize = 10;
        }

        doc.setFontSize(fontSize);
        doc.text(word, wordX, wordY, { align: 'center' });
      });
    });

    doc.save(`bingo_cards_${selectedForm}_${gridSize}x${gridSize}.pdf`);
  };

  return (
    <div className="bg-sky-300 min-h-screen p-10 flex flex-col items-center">
      <h1 className="text-4xl font-extrabold mb-8 text-gray-900">
        Bingo Card Generator
      </h1>

      {/* Controls Panel */}
      <div className="bg-sky-200 p-6 rounded-lg shadow-md w-full max-w-4xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Left Column */}
          <div className="space-y-6">
            {/* Select Dataset */}
            <div>
              <label htmlFor="datasetSelector" className="block text-lg font-semibold text-gray-700 mb-2">
                Select Dataset:
              </label>
              <select
                id="datasetSelector"
                value={selectedDataset}
                onChange={(e) => setSelectedDataset(e.target.value)}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 shadow-sm transition ease-in-out duration-200"
              >
                <option value="">--Select Dataset--</option>
                {datasets.map((dataset) => (
                  <option key={dataset.file_name} value={dataset.file_name}>
                    {dataset.display_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Select Mode */}
            {columns.length > 0 && (
              <VerbFormSelector options={columns} setSelectedForm={setSelectedForm} />
            )}
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            {/* Grid Size */}
            <div>
              <label htmlFor="gridSizeSelector" className="block text-lg font-semibold text-gray-700 mb-2">
                Select Grid Size:
              </label>
              <select
                id="gridSizeSelector"
                value={gridSize}
                onChange={(e) => setGridSize(parseInt(e.target.value, 10))}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 shadow-sm transition ease-in-out duration-200"
              >
                <option value={3}>3 x 3</option>
                <option value={4}>4 x 4</option>
                <option value={5}>5 x 5</option>
                <option value={6}>6 x 6</option>
              </select>
            </div>

            {/* Number of Cards */}
            <div>
              <label htmlFor="cardNumberSelector" className="block text-lg font-semibold text-gray-700 mb-2">
                Number of Cards:
              </label>
              <input
                type="number"
                id="cardNumberSelector"
                value={numCards}
                onChange={(e) => setNumCards(parseInt(e.target.value, 10))}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 shadow-sm transition ease-in-out duration-200"
                min="1"
              />
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex flex-col md:flex-row gap-4">
          <button
            onClick={generateBingoCards}
            className="flex items-center justify-center w-full md:w-1/2 bg-green-500 text-white font-semibold py-3 px-4 rounded-lg shadow-md hover:bg-green-600 transition duration-200"
            disabled={!selectedDataset || !selectedForm}
          >
            <IoMdRefresh className="mr-2 text-2xl" />
            Generate Bingo Cards
          </button>
          <button
            onClick={downloadPDF}
            className="flex items-center justify-center w-full md:w-1/2 bg-sky-500 text-white font-semibold py-3 px-4 rounded-lg shadow-md hover:bg-sky-600 transition duration-200"
            disabled={cards.length === 0}
          >
            <FaDownload className="mr-2 text-xl" />
            Download PDF
          </button>
        </div>
      </div>

      {/* Display generated cards */}
      {cards.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 w-full max-w-screen">
          {cards.map((card, cardIndex) => (
            <div
              key={cardIndex}
              className="border-4 border-gray-600 p-4 bg-white rounded-md shadow-md grid"
              style={{
                gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
                gap: '4px',
              }}
            >
              {card.map((word, index) => (
                <div
                  key={index}
                  className="border border-gray-300 p-3 rounded-md text-center bg-green-100 text-lg font-semibold"
                >
                  {word}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default BingoCardGenerator;