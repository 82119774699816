// src/auth/auth.js

import axios from 'axios';

const CLOUD_URL = 'https://aurora.anvicit.pl/api'; // Ensure this URL is correct
const LOCAL_URL = 'http://localhost:8000/api'; // Use the correct URL
const API_BASE_URL = CLOUD_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const login = async (credentials) => {
  try {
    const response = await api.post('/token/', credentials);
    return response.data; // Expecting { access, refresh }
  } catch (error) {
    throw new Error('Login failed');
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

// New API call to generate characters
export const generateCharacter = async (ageRange, topic) => {
  try {
    const response = await api.post('/generate-character', {
      ageRange, 
      topic,
    });
    return response.data.character; // Returning the character object
  } catch (error) {
    throw new Error('Error generating character');
  }
};
