import React, { useState, useEffect } from 'react';
import Flashcard from './Flashcard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const FlashcardDeck = ({ flashcards }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Check if flashcards prop is not null or undefined
  useEffect(() => {
    if (flashcards && flashcards.length > 0) {
      setActiveIndex(0); // Reset the active index if flashcards change
    }
  }, [flashcards]);

  return (
    <div className="w-full max-w-6xl mx-auto pb-12">
      {flashcards && flashcards.length > 0 ? (
        <Swiper
          modules={[Navigation, Pagination, EffectCoverflow]}
          spaceBetween={30}
          centeredSlides={true}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          effect="coverflow"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1.5, // Increased modifier for larger central card
            slideShadows: true,
          }}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          breakpoints={{
            // Responsive breakpoints
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          className="mySwiper"
          allowTouchMove={true} // Enable swiping
        >
          {flashcards.map((card, index) => (
            <SwiperSlide key={card.id}>
              <Flashcard flashcard={card} isActive={index === activeIndex} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="text-center text-gray-600">
          <p>No flashcards available for this set.</p>
        </div>
      )}
    </div>
  );
};

export default FlashcardDeck;
