// src/components/PressConference.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PressConference = () => {
  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recentCharacters, setRecentCharacters] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [settings, setSettings] = useState({
    ageRange: "12-15",
    topic: "superheroes",
  });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [tempSettings, setTempSettings] = useState(settings);

  const openAIKey = "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";

  const fetchCharacterFromOpenAI = async (ageRange, topic) => {
    setLoading(true);
    setError(null);

    const systemPrompt = `Provide a well-known character or famous figure that a ${ageRange} year-old from Poland would recognize. The topic is ${topic} (e.g., characters from books, movies, or real life). Avoid the following characters: ${recentCharacters.join(", ")}. Return the response in the following JSON format:
    {
      "name": "Character Name in English if version exists",
      "personality_traits": ["trait1", "trait2", "trait3"],
      "description": "A brief description of the character",
      "banned_words": ["word1", "word2", "word3"]
    }. Ensure that the banned words are closely related to the character to prevent obvious guesses. Avoid making up fictional characters.`;

    const body = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: "You are a helpful assistant." },
        { role: "user", content: systemPrompt },
      ],
      max_tokens: 200,
      temperature: 0.7,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        body,
        {
          headers: {
            Authorization: `Bearer ${openAIKey}`,
            "Content-Type": "application/json",
          },
        }
      );

      let generatedText = response.data.choices[0].message.content.trim();
      const jsonMatch = generatedText.match(/\{[^]*\}/);

      if (jsonMatch) {
        const jsonString = jsonMatch[0];
        let characterData;
        try {
          characterData = JSON.parse(jsonString);
          setCharacter(characterData);
          setRecentCharacters([...recentCharacters, characterData.name].slice(-5));
        } catch (jsonError) {
          setError("Failed to parse character data.");
        }
      } else {
        setError("No valid character data found.");
      }
    } catch (err) {
      setError("Failed to fetch character.");
    } finally {
      setLoading(false);
    }
  };

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const openSettings = () => {
    setTempSettings(settings);
    setIsSettingsOpen(true);
  };

  const closeSettings = () => {
    setIsSettingsOpen(false);
  };

  const saveSettings = () => {
    setSettings(tempSettings);
    setIsSettingsOpen(false);
    fetchCharacterFromOpenAI(tempSettings.ageRange, tempSettings.topic);
  };

  useEffect(() => {
    fetchCharacterFromOpenAI(settings.ageRange, settings.topic);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${fullScreen ? "h-screen" : ""} bg-blue-100 min-h-screen p-10 flex flex-col items-center`}>
      <h1 className="text-4xl font-bold mb-8 text-gray-900">The Press Conference</h1>

      <div className="flex space-x-4 mb-4">
        <button
          onClick={toggleFullScreen}
          className="bg-yellow-500 text-white py-2 px-4 rounded shadow hover:bg-yellow-600"
        >
          {fullScreen ? "Exit Full Screen" : "Full Screen Mode"}
        </button>
        <button
          onClick={openSettings}
          className="bg-purple-600 text-white py-2 px-4 rounded shadow hover:bg-purple-700"
        >
          Settings
        </button>
      </div>

      {loading ? (
        <p className="text-lg">Loading character...</p>
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-6 text-center w-full max-w-4xl">
          {error ? (
            <p className="text-red-500">{error}</p>
          ) : character ? (
            <div className="flex flex-col items-center">
              <h2 className="text-3xl font-bold mb-4 text-green-600">Character Info</h2>
              <div className="w-full bg-gray-100 p-6 rounded-lg text-left">
                <p className="text-xl font-bold">Name: {character.name}</p>
                <p className="text-lg mt-4">Personality Traits: {character.personality_traits.join(', ')}</p>
                <p className="text-lg mt-4">Description: {character.description}</p>
                <p className="text-lg mt-4">Banned Words: {character.banned_words.join(', ')}</p>
              </div>
            </div>
          ) : (
            <p className="text-lg">No character generated yet.</p>
          )}

          <button
            onClick={() => fetchCharacterFromOpenAI(settings.ageRange, settings.topic)}
            className="bg-green-600 text-white py-2 px-4 rounded shadow hover:bg-green-700 mt-4"
          >
            Generate Character
          </button>
        </div>
      )}

      {isSettingsOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-8 w-11/12 max-w-md">
            <h2 className="text-2xl font-bold mb-4">Settings</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Group Age Range:</label>
              <input
                type="text"
                value={tempSettings.ageRange}
                onChange={(e) => setTempSettings({ ...tempSettings, ageRange: e.target.value })}
                className="mt-1 p-2 w-full border rounded"
                placeholder="e.g., 12-15"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Topic:</label>
              <input
                type="text"
                value={tempSettings.topic}
                onChange={(e) => setTempSettings({ ...tempSettings, topic: e.target.value })}
                className="mt-1 p-2 w-full border rounded"
                placeholder="e.g., superheroes, historical figures"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={closeSettings}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded shadow hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={saveSettings}
                className="bg-blue-600 text-white py-2 px-4 rounded shadow hover:bg-blue-700"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PressConference;
