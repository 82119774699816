import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Howl } from 'howler';

const ItemTypes = {
  SHAPE: 'shape',
};

// Audio files for shapes (placeholders, replace with real audio paths)
const shapeSounds = {
  Circle: '/audio/circle.mp3',
  Square: '/audio/square.mp3',
  Triangle: '/audio/triangle.mp3',
};

const DraggableShape = ({ shape, color }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.SHAPE,
    item: { shape },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const playShapeSound = () => {
    const sound = new Howl({ src: [shapeSounds[shape]] });
    sound.play();
  };

  return (
    <div className="flex flex-col items-center">
      <div
        ref={drag}
        className={`w-16 h-16 flex items-center justify-center rounded-full ${color}`}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: 'move',
          transform: isDragging ? 'scale(1.1)' : 'scale(1)',
          transition: 'transform 0.2s ease',
        }}
      >
        <span className="text-white text-lg">{shape}</span>
      </div>
      <button
        onClick={playShapeSound}
        className="mt-2 bg-blue-500 text-white px-2 py-1 rounded-lg text-sm"
      >
        Play Sound
      </button>
    </div>
  );
};

const DropArea = ({ acceptShape, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.SHAPE,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={`w-64 h-64 rounded-lg flex items-center justify-center transition-all duration-300 ${
        isOver ? 'bg-green-200 border-green-500' : 'bg-blue-100 border-gray-500'
      }`}
      style={{ border: '2px solid', transition: 'background 0.2s ease' }}
    >
      {acceptShape ? <span className="text-2xl font-bold">{acceptShape}</span> : 'Drop here!'}
    </div>
  );
};

const DragAndDrop = () => {
  const [points, setPoints] = useState(0);
  const [currentShape, setCurrentShape] = useState(null);

  const handleDrop = (item) => {
    if (item.shape === currentShape) {
      setPoints((prevPoints) => prevPoints + 1);
      setCurrentShape(null); // Clear current shape
    } else {
      alert('Wrong shape!');
    }
  };

  const startNewRound = (shape) => {
    setCurrentShape(shape);
  };

  return (
    <div className="flex flex-col items-center space-y-8">
      <h2 className="text-3xl font-bold mb-6 text-blue-700">Drag and Drop the Shape!</h2>
      <p className="text-xl">Points: {points}</p>

      {/* Drop Area */}
      <DropArea acceptShape={currentShape} onDrop={handleDrop}>
        {currentShape ? `Place the ${currentShape}` : 'Choose a shape to start!'}
      </DropArea>

      {/* Draggable shapes */}
      <div className="flex space-x-4">
        {['Circle', 'Square', 'Triangle'].map((shape) => (
          <DraggableShape key={shape} shape={shape} color={`bg-${shape.toLowerCase()}-500`} />
        ))}
      </div>

      {/* Shape Buttons */}
      <div className="mt-6 flex space-x-4">
        {['Circle', 'Square', 'Triangle'].map((shape) => (
          <button
            key={shape}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
            onClick={() => startNewRound(shape)}
          >
            Start with {shape}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DragAndDrop;
