import React from 'react';

const StartButton = ({ onStart }) => {
  return (
    <button 
      onClick={onStart}
      className="bg-green-500 text-white font-bold py-2 px-4 rounded-full hover:bg-green-600 transition duration-300"
    >
      Start Game
    </button>
  );
};

export default StartButton;
