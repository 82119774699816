import React from 'react';
import DraggableShape from './DraggableShape';

const shapes = [
  { shape: 'Circle', color: 'bg-red-500' },
  { shape: 'Square', color: 'bg-blue-500' },
  { shape: 'Triangle', color: 'bg-green-500' },
  { shape: 'Star', color: 'bg-yellow-500' }
];

const ShapeSelector = () => {
  return (
    <div className="flex justify-around">
      {shapes.map((shape, index) => (
        <DraggableShape key={index} shape={shape.shape} color={shape.color} />
      ))}
    </div>
  );
};

export default ShapeSelector;
