// src/components/ModeSelection.js

import React from 'react';
import { FaBookOpen, FaHistory, FaRandom } from 'react-icons/fa';

const ModeSelection = ({ onSelectMode }) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-300 to-indigo-500">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <h2 className="text-3xl font-bold mb-6">Select Tense Mode</h2>
        <div className="flex flex-col space-y-4">
          <button
            onClick={() => onSelectMode('present')}
            className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
          >
            <FaBookOpen className="mr-2" />
            Present Simple
          </button>
          <button
            onClick={() => onSelectMode('past')}
            className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
          >
            <FaHistory className="mr-2" />
            Past Simple
          </button>
          <button
            onClick={() => onSelectMode('mixed')}
            className="flex items-center justify-center px-6 py-3 bg-yellow-600 text-white rounded-md hover:bg-yellow-700 transition duration-300"
          >
            <FaRandom className="mr-2" />
            Mixed Tenses
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModeSelection;