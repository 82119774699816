import React, { useState } from 'react';
import FlipCard from '../components/FlipCard';
import DragAndDrop from '../components/DragAndDrop';

const YoungestLearnersPlay = () => {
  const [vocabItems, setVocabItems] = useState([
    { front: 'Dog', back: 'Pies' },
    { front: 'Cat', back: 'Kot' },
    { front: 'Apple', back: 'Jabłko' },
  ]);

  const handleCardDone = () => {
    setVocabItems((prevItems) => prevItems.slice(1)); // Remove the first item when done
  };

  return (
    <div className="min-h-screen bg-yellow-100 flex flex-col items-center justify-center">
      <h1 className="text-3xl font-bold text-blue-900 mb-8">Let's Play!</h1>

      {/* Flip Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {vocabItems.length > 0 ? (
          <FlipCard
            frontContent={vocabItems[0].front}
            backContent={vocabItems[0].back}
            onDone={handleCardDone}
          />
        ) : (
          <p className="text-2xl font-bold">All Done!</p>
        )}
      </div>

      {/* Drag and Drop Section */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-blue-800 mb-4">Drag and Drop the Shapes!</h2>
        <DragAndDrop />
      </div>
    </div>
  );
};

export default YoungestLearnersPlay;
