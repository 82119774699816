// src/components/Dashboard.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FaBook, FaClipboardList, FaBrain, FaTable, FaGamepad, FaThLarge, FaInfoCircle, FaChalkboardTeacher, FaChild }  from 'react-icons/fa';

const Dashboard = ({ user }) => {
  return (
    <div className="container mx-auto p-6">
            <div className="bg-gray-100 shadow-lg rounded-lg p-6 mb-8 border-l-4 border-green-600 max-w-fit">
                <div className="flex items-center">
                      <div className="">
                        <h2 className="text-3xl font-bold mb-6 text-center text-bluey-900">Welcome, {user.username}!</h2>
                      </div>
                </div>
            </div>
      {/* User Information Card */}
      <div className="bg-gray-100 shadow-lg rounded-lg p-6 mb-8 border-l-4 border-bluey-500">
        <div className="flex items-center">
          <div className="bg-bluey-500 p-4 rounded-full mr-4">
            <FaBrain className="text-white text-3xl" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Profile Information</h3>
            <p className="text-gray-600"><strong>Email:</strong> {user.email}</p>
            <p className="text-gray-600"><strong>Status:</strong> {user.role.charAt(0).toUpperCase() + user.role.slice(1)}</p>
          </div>
        </div>
      </div>
      
      {/* Student Navigation */}
      {user.role === 'student' && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Sentence Builder */}
          <Link to="/sentence-game">
            <div className="bg-green-700 hover:bg-green-800 text-white rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaBook className="text-4xl" />
              <div>
                <h4 className="text-xl font-semibold">Sentence Builder</h4>
                <p className="text-sm">Build sentences using correct grammar.</p>
              </div>
            </div>
          </Link>
          
          {/* Flashcards */}
          <Link to="/student-flashcards">
            <div className="bg-purple-700 hover:bg-purple-800 text-white rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaClipboardList className="text-4xl" />
              <div>
                <h4 className="text-xl font-semibold">Flashcards</h4>
                <p className="text-sm">Practice vocabulary with flashcards.</p>
              </div>
            </div>
          </Link>
        </div>
      )}
      
      {/* Admin Navigation */}
      {user.role === 'admin' && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Manage Groups */}
          <Link to="/card-generator">
            <div className="bg-white border-bluey-500 border-l-4 rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaThLarge className="text-bluey-500 text-4xl" />
              <div>
              <h4 className="text-xl font-semibold">Card Generator</h4>
              <p className="text-sm">Create custom cards for various activities.</p>
              </div>
            </div>
          </Link>
          
          {/* Assign Flashcards */}
          <Link to="/bingo-app">
            <div className="bg-white border-purple-500 border-l-4 rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaGamepad className="text-purple-500 text-4xl" />
              <div>
              <h4 className="text-xl font-semibold">Bingo App</h4>
              <p className="text-sm">Engaging Bingo activities for students.</p>
              </div>
            </div>
          </Link>
          
          {/* Vocabulary Sets */}
          <Link to="/datasets">
            <div className="bg-white border-green-500 border-l-4 rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaTable className="text-green-500 text-4xl" />
              <div>
              <h4 className="text-xl font-semibold">Datasets</h4>
              <p className="text-sm">Manage vocabulary and datasets.</p>
              </div>
            </div>
          </Link>
          
          {/* Control Mode */}
          <Link to="/control-mode">
            <div className="bg-white border-goldy-500 border-l-4 rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaChalkboardTeacher className="text-goldy-500 text-4xl" />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">Control Center</h4>
                <p className="text-sm text-gray-600">Manage application settings and assignments.</p>
              </div>
            </div>
          </Link>
          
          {/* Youngest Learners Game */}
          <Link to="/youngest-learners">
            <div className="bg-white border-emerald-700 border-l-4 rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaChild className="text-emerald-700 text-4xl" />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">Youngest Learners Game</h4>
                <p className="text-sm text-gray-600">Interactive game for 7-8 year olds.</p>
              </div>
            </div>
          </Link>
          
          {/* Info */}
          <Link to="/info">
            <div className="bg-white border-sky-800 border-l-4 rounded-lg shadow-md p-6 flex items-center space-x-4 transition duration-300">
              <FaInfoCircle className="text-sky-800 text-4xl" />
              <div>
                <h4 className="text-xl font-semibold text-gray-800">Info</h4>
                <p className="text-sm text-gray-600">Access informational resources.</p>
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
