// src/components/SelectVocabularySet.js

import React, { useState, useEffect } from 'react';
import { api } from '../auth/auth'; // Ensure your API instance includes authentication headers
import { useNavigate } from 'react-router-dom';
import { FaBook, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';
import { FiArrowRight, FiPlay } from 'react-icons/fi'; // Import FiPlay

const SelectVocabularySet = () => {
  const [vocabSets, setVocabSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState('');
  const [directLoadingSetId, setDirectLoadingSetId] = useState(null); // Loading state for direct start
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVocabularySets = async () => {
      try {
        const response = await api.get(`/vocabulary-sets/by-group/`); // Adjust this if necessary
        setVocabSets(response.data); // Sets will be filtered by the user's group in the API
        setLoading(false);
      } catch (err) {
        console.error('Error fetching vocabulary sets:', err);
        setError('Failed to load vocabulary sets. Please try again later.');
        setLoading(false);
      }
    };

    fetchVocabularySets();
  }, []);




  const handleDirectStart = async (setId) => {
    try {
      setDirectLoadingSetId(setId);
      // Perform any asynchronous operations if needed
      navigate(`/flashcards/${setId}`);
    } catch (err) {
      console.error('Error navigating to flashcards:', err);
      alert('Failed to start practice. Please try again.');
    } finally {
      setDirectLoadingSetId(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 to-purple-100">
        <FaSpinner className="animate-spin text-4xl text-blue-500 mr-4" />
        <span className="text-xl text-blue-600">Loading vocabulary sets...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-red-100 to-pink-100">
        <div className="flex flex-col items-center bg-red-200 p-8 rounded-lg shadow-lg">
          <FaTimesCircle className="text-red-600 text-6xl mb-4" />
          <h2 className="text-3xl font-semibold text-red-700 mb-2">Error</h2>
          <p className="text-center text-red-600">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-6 flex items-center px-6 py-3 bg-red-400 text-white font-semibold rounded-md shadow hover:bg-red-500 transition duration-300"
          >
            <FaCheckCircle className="mr-2" />
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-100 to-blue-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="mb-12 text-center">
          <FaBook className="mx-auto h-12 w-12 text-blue-600" />
          <h2 className="mt-6 text-4xl font-extrabold text-gray-900">Choose Your Vocabulary Set</h2>
          <p className="mt-4 text-lg text-gray-600">
            Select a set below to start practicing your vocabulary with interactive flashcards.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {vocabSets.map((set) => (
            <div
              key={set.id}
              className={`cursor-pointer bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 ${
                selectedSet === set.id ? 'border-4 border-blue-500' : ''
              }`}
            >
              <div className="flex items-center justify-between">
                <FaBook className="text-blue-500 text-3xl" />
                {selectedSet === set.id && <FaCheckCircle className="text-green-500 text-xl" />}
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-800">{set.name}</h3>
              <p className="mt-2 text-gray-600">{set.description || 'No description available.'}</p>

              {/* Existing Selection Area */}
              <div
                onClick={() => setSelectedSet(set.id)}
                className="mt-4 flex items-center justify-center"
              >

              </div>

              {/* New Direct Start Button */}
              <div className="mt-4 flex items-center justify-center">
                <button
                  onClick={() => handleDirectStart(set.id)}
                  className="flex items-center px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow hover:bg-green-600 transition duration-300 relative"
                  aria-label={`Start practice for ${set.name}`}
                  disabled={directLoadingSetId === set.id}
                >
                  {directLoadingSetId === set.id ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Starting...
                    </>
                  ) : (
                    <>
                      <FiPlay className="mr-2" />
                      Start Now
                    </>
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>


      </div>
    </div>
  );
};

export default SelectVocabularySet;
