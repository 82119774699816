// src/components/VerbFormSelector.jsx
import React from 'react';

function VerbFormSelector({ options, setSelectedForm }) {
  const handleChange = (e) => {
    setSelectedForm(e.target.value);
  };

  return (
    <div>
      <label htmlFor="verbFormSelector" className="block text-lg font-semibold text-gray-700 mb-2">
        Select Mode:
      </label>
      <div className="relative">
        <select
          id="verbFormSelector"
          onChange={handleChange}
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 transition ease-in-out duration-200"
        >
          <option value="">--Select Mode--</option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M5.516 7.548a.75.75 0 011.06 0L10 10.922l3.424-3.374a.75.75 0 111.06 1.06l-4.005 3.934a.75.75 0 01-1.06 0L5.516 8.608a.75.75 0 010-1.06z" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default VerbFormSelector;