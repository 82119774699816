import React from 'react';
import axios from 'axios';
import { AiOutlineSound } from 'react-icons/ai';

const PlayButton = ({ word, size=20, lang = 'en-UK' }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const playWord = async () => {
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const lang2 = lang;
    const data = {
      input: { text: word },
      voice: { languageCode: lang2, ssmlGender: 'NEUTRAL' },
      audioConfig: { audioEncoding: 'MP3' },
    };

    try {
      const response = await axios.post(url, data);
      const audioContent = response.data.audioContent;
      const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
      audio.play();
    } catch (error) {
      console.error('Error with text-to-speech:', error);
    }
  };

  return (
    <button onClick={playWord} className="text-blue-500">
      <AiOutlineSound size={size} />
    </button>
  );
};

export default PlayButton;
