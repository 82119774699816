// YoungestLearnersGame.js
import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaCircle, FaSquareFull, FaStar } from 'react-icons/fa';
import PlayButton from './PlayButton';

// Define the type for draggable items
const ItemTypes = {
  SHAPE: 'shape',
};

// DraggableShape Component
const DraggableShape = ({ shape, color }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.SHAPE,
    item: { shape, color },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  // Define icon based on shape
  const renderShapeIcon = (shape) => {
    switch (shape) {
      case 'Circle':
        return <FaCircle />;
      case 'Square':
        return <FaSquareFull />;
      case 'Star':
        return <FaStar />;
      default:
        return null;
    }
  };

  return (
    <div
      ref={drag}
      className={`w-24 h-24 flex items-center justify-center text-4xl ${
        color === 'Yellow'
          ? 'text-yellow-600'
          : color === 'Blue'
          ? 'text-blue-600'
          : color === 'Red'
          ? 'text-red-600'
          : color === 'Green'
          ? 'text-green-600'
          : 'text-purple-600'
      } border-2 border-gray-300 rounded-md cursor-grab ${
        isDragging ? 'opacity-50 scale-110' : 'opacity-100'
      } transition-opacity transform duration-200`}
    >
      {renderShapeIcon(shape)}
    </div>
  );
};

// DropArea Component
const DropArea = ({ onDrop, targetShape, targetColor }) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.SHAPE,
      drop: (item) => onDrop(item),
      canDrop: (item) =>
        item.shape.toLowerCase() === targetShape.toLowerCase() &&
        item.color.toLowerCase() === targetColor.toLowerCase(),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [targetShape, targetColor]
  );

  const getBackground = () => {
    if (isOver) {
      return canDrop ? 'bg-green-200' : 'bg-red-200';
    }
    return 'bg-gray-100';
  };

  return (
    <div
      ref={drop}
      className={`w-80 h-80 flex flex-col items-center justify-center mt-8 border-4 ${getBackground()} border-gray-300 rounded-xl transition-colors duration-300`}
    >
      <p className="text-lg font-semibold">{`Drop the ${targetColor} ${targetShape} here`}</p>
      <div className="mt-4 text-center text-sm text-gray-600">
        {isOver
          ? canDrop
            ? 'Release to Submit!'
            : 'Cannot Drop Here!'
          : 'Drag the correct shape and color'}
      </div>
    </div>
  );
};

// SuccessMessage Component
// SuccessMessage Component
const SuccessMessage = ({ onNext }) => {
  // Function to refresh the page
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="mt-6 p-6 bg-green-100 border border-green-400 text-green-700 rounded shadow">
      <p className="text-xl mb-4">🎉 Correct! Well done!</p>
      <button
        onClick={refreshPage}
        className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors mr-4"
      >
        Next Round
      </button>
 
    </div>
  );
};

// Main YoungestLearnersGame Component
const YoungestLearnersGame = () => {
  // Define shapes and colors
  const shapes = ['Circle', 'Square', 'Star'];
  const colors = ['Red', 'Blue', 'Yellow', 'Green', 'Purple'];

  // State variables
  const [instruction, setInstruction] = useState('');
  const [options, setOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [round, setRound] = useState(1); // To track rounds

  // Utility function to shuffle arrays
  const shuffleArray = (array) => [...array].sort(() => Math.random() - 0.5);

  // Initialize a new game round
  const startGame = () => {
    console.log(`\nStarting Round ${round}`);

    // Select a random shape and color as the correct answer
    const randomShape = shapes[Math.floor(Math.random() * shapes.length)];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    const correct = { shape: randomShape, color: randomColor };
    console.log('Correct Answer:', correct);

    // Generate three wrong options
    const wrongOptions = [];
    while (wrongOptions.length < 3) {
      const shape = shapes[Math.floor(Math.random() * shapes.length)];
      const color = colors[Math.floor(Math.random() * colors.length)];
      // Ensure no duplicates and not the correct answer
      if (
        !(shape === correct.shape && color === correct.color) &&
        !wrongOptions.some((opt) => opt.shape === shape && opt.color === color)
      ) {
        wrongOptions.push({ shape, color });
      }
    }

    console.log('Wrong Options:', wrongOptions);

    // Combine and shuffle options
    const allOptions = shuffleArray([correct, ...wrongOptions]);
    console.log('All Options:', allOptions);

    // Set state
    setInstruction(`Drag the ${correct.color} ${correct.shape}`);
    setOptions(allOptions);
    setCorrectAnswer(correct);
    setShowSuccess(false);
    setFeedback('');
  };

  // Handle the drop event
  const handleDrop = (droppedItem) => {
    console.log('Dropped Item:', droppedItem);
    console.log('Current Correct Answer:', correctAnswer);

    if (!correctAnswer) {
      console.error('No correct answer set.');
      return;
    }

    const droppedShape = droppedItem.shape.trim().toLowerCase();
    const droppedColor = droppedItem.color.trim().toLowerCase();
    const targetShape = correctAnswer.shape.trim().toLowerCase();
    const targetColor = correctAnswer.color.trim().toLowerCase();

    if (droppedShape === targetShape && droppedColor === targetColor) {
      console.log('Correct drop!');
      setShowSuccess(true);
      setFeedback('');
    } else {
      console.log('Incorrect drop!');
      setFeedback('Incorrect! Please try again.');
      // Remove feedback after 2 seconds
      setTimeout(() => setFeedback(''), 2000);
    }
  };

  // Start the first game round on component mount
  useEffect(() => {
    startGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Next Round
  const handleNextRound = () => {
    setRound((prevRound) => prevRound + 1);
  };

  useEffect(() => {
    // Each time round increments, start a new game
    if (round > 1) {
      startGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="min-h-screen bg-gradient-to-r from-yellow-100 to-yellow-200 flex flex-col items-center justify-center p-6">
        <div className="bg-white bg-opacity-90 rounded-3xl shadow-2xl p-8 max-w-lg w-full">
          <div className='flex flex-col items-center space-y-4  my-4'>
          {/* Instruction */}
          <PlayButton word={instruction} lang='en-UK' size={36}/>
          <h2 className="text-2xl font-bold text-center">{instruction}</h2>
          
    </div>
          {/* Draggable Options */}
          <div className="grid grid-cols-4 gap-6">
            {options.map((option, index) => (
              <DraggableShape key={index} shape={option.shape} color={option.color} />
            ))}
          </div>

          {/* Drop Area */}
          {correctAnswer && (
            <DropArea
              key={`${correctAnswer.shape}-${correctAnswer.color}-${round}`} // Unique key to force DropArea to remount each round
              onDrop={handleDrop}
              targetShape={correctAnswer.shape}
              targetColor={correctAnswer.color}
            />
          )}

          {/* Feedback Message */}
          {feedback && <p className="text-red-500 mt-4 text-center">{feedback}</p>}

          {/* Success Message */}
          {showSuccess && <SuccessMessage onNext={handleNextRound} />}
        </div>
      </div>
    </DndProvider>
  );
};

export default YoungestLearnersGame;
