import React, { useState } from 'react';
import StartButton from './StartButton';
import ShapeSelector from './ShapeSelector';
import GameArea from './GameArea';

const GamePage = () => {
  const [gameStarted, setGameStarted] = useState(false);

  const handleStart = () => {
    setGameStarted(true);
  };

  return (
    <div className="min-h-screen bg-yellow-50 flex flex-col items-center justify-center space-y-8">
      {!gameStarted ? (
        <StartButton onStart={handleStart} />
      ) : (
        <>
          <ShapeSelector />
          <GameArea />
        </>
      )}
    </div>
  );
};

export default GamePage;
