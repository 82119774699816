// src/components/ControlMode.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FaCog, FaUsers, FaChalkboardTeacher, FaClipboardList } from 'react-icons/fa';

const ControlMode = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-700">Control Center</h2>
        
        {/* Control Options */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Manage Groups */}
          <Link to="/manage-groups">
            <div className="flex items-center p-6 bg-blue-500 hover:bg-blue-600 text-white rounded-lg shadow-lg transition duration-300">
              <FaUsers className="text-4xl mr-4" />
              <div>
                <h3 className="text-2xl font-semibold">Manage Groups</h3>
                <p className="text-sm">Create and organize student groups.</p>
              </div>
            </div>
          </Link>
          
          {/* Assign Flashcards */}
          <Link to="/assign-flashcards">
            <div className="flex items-center p-6 bg-indigo-500 hover:bg-indigo-600 text-white rounded-lg shadow-lg transition duration-300">
              <FaClipboardList className="text-4xl mr-4" />
              <div>
                <h3 className="text-2xl font-semibold">Assign Flashcards</h3>
                <p className="text-sm">Assign flashcards to specific groups.</p>
              </div>
            </div>
          </Link>
          
          {/* Manage Vocabulary Sets */}
          <Link to="/vocabulary-sets">
            <div className="flex items-center p-6 bg-purple-500 hover:bg-purple-600 text-white rounded-lg shadow-lg transition duration-300">
              <FaChalkboardTeacher className="text-4xl mr-4" />
              <div>
                <h3 className="text-2xl font-semibold">Vocabulary Sets</h3>
                <p className="text-sm">Create and manage vocabulary sets.</p>
              </div>
            </div>
          </Link>
          
          {/* Settings */}
          <Link to="/settings">
            <div className="flex items-center p-6 bg-gray-500 hover:bg-gray-600 text-white rounded-lg shadow-lg transition duration-300">
              <FaCog className="text-4xl mr-4" />
              <div>
                <h3 className="text-2xl font-semibold">Settings</h3>
                <p className="text-sm">Configure application settings.</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ControlMode;
