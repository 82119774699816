import React, { useState, useEffect } from 'react';
import { api } from '../auth/auth';
import { useNavigate } from 'react-router-dom';
import { FaBook, FaSpinner, FaCheckCircle, FaExclamationCircle, FaBookOpen } from 'react-icons/fa';
import { FiPlay } from 'react-icons/fi';

const StudentFlashcards = ({ user }) => {
  const [vocabSets, setVocabSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState(null);
  const [directLoadingSetId, setDirectLoadingSetId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVocabSets = async () => {
      try {
        const response = await api.get(`/vocabulary-sets/by-group/`);
        setVocabSets(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching vocabulary sets:', err);
        setError('Failed to load vocabulary sets. Please try again later.');
        setLoading(false);
      }
    };

    fetchVocabSets();
  }, [user]);

  const handleDirectStart = async (setId) => {
    try {
      setDirectLoadingSetId(setId);
      navigate(`/flashcards/${setId}`);
    } catch (err) {
      console.error('Error navigating to flashcards:', err);
      alert('Failed to start practice. Please try again.');
    } finally {
      setDirectLoadingSetId(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 to-purple-100">
        <FaSpinner className="animate-spin text-4xl text-blue-500 mr-4" />
        <span className="text-lg sm:text-xl text-blue-600">Loading vocabulary sets...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-red-100 to-pink-100">
        <div className="flex flex-col items-center bg-red-200 p-8 rounded-lg shadow-lg">
          <FaExclamationCircle className="text-red-600 text-6xl mb-4" />
          <h2 className="text-3xl sm:text-4xl font-semibold text-red-700 mb-2">Error</h2>
          <p className="text-center text-red-600">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-6 flex items-center px-6 py-3 bg-red-400 text-white font-semibold rounded-md shadow hover:bg-red-500 transition duration-300"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (vocabSets.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-yellow-100 to-orange-100">
        <div className="flex flex-col items-center bg-yellow-200 p-8 rounded-lg shadow-lg">
          <FaExclamationCircle className="text-yellow-600 text-4xl mb-4" />
          <h2 className="text-2xl sm:text-3xl font-semibold text-yellow-700 mb-2">No Vocabulary Sets Available</h2>
          <p className="text-center text-yellow-600">There are no vocabulary sets available at this time.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="mb-12 text-center rounded-lg bg-gray-100 p-4">
          <h2 className="text-3xl sm:text-4xl font-light text-gray-800">Choose Your Vocabulary Set</h2>
          <p className="mt-4 text-lg sm:text-xl text-gray-800">Select a set below to start practicing.</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {vocabSets.map((set) => (
            <div
              key={set.id}
              className={`cursor-pointer bg-white p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 ${
                selectedSet === set.id ? 'border-4 border-blue-500' : ''
              }`}
              onClick={() => setSelectedSet(set.id)}
            >
              <div className="flex items-center justify-between">
                <FaBook className="text-blue-500 text-3xl" />
                {selectedSet === set.id && <FaCheckCircle className="text-green-500 text-xl" />}
              </div>
              <h3 className="mt-4 text-xl sm:text-2xl font-semibold text-gray-800">{set.name}</h3>
              <p className="mt-2 text-gray-600">{set.description || 'No description available.'}</p>

              <div className="mt-4 flex items-center justify-center">
                <button
                  onClick={() => handleDirectStart(set.id)}
                  className="flex items-center px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow hover:bg-green-600 transition duration-300 relative"
                  aria-label={`Start practice for ${set.name}`}
                  disabled={directLoadingSetId === set.id}
                >
                  {directLoadingSetId === set.id ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Starting...
                    </>
                  ) : (
                    <>
                      <FiPlay className="mr-2" />
                      Start Now
                    </>
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentFlashcards;
