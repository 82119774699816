// DraggableShape.js
import React from 'react';
import { useDrag } from 'react-dnd';
import { BsTriangleFill } from 'react-icons/bs';
import { FaSquareFull, FaCircle, FaStar } from 'react-icons/fa';
import { MdRectangle } from 'react-icons/md';

const ItemTypes = {
  SHAPE: 'shape',
};

// Mapping of shape names to their corresponding icons
const shapeIcons = {
  Circle: <FaCircle />,
  Square: <FaSquareFull />,
  Triangle: <BsTriangleFill />,
  Star: <FaStar />,
  Rectangle: <MdRectangle />,
};

// Mapping of color names to Tailwind CSS classes
const colorClasses = {
  red: 'text-red-500 border-red-500',
  blue: 'text-blue-500 border-blue-500',
  green: 'text-green-500 border-green-500',
  yellow: 'text-yellow-500 border-yellow-500',
  purple: 'text-purple-500 border-purple-500',
  orange: 'text-orange-500 border-orange-500',
  pink: 'text-pink-500 border-pink-500',
  cyan: 'text-cyan-500 border-cyan-500',
  teal: 'text-teal-500 border-teal-500',
};

const DraggableShape = ({ shape, color }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.SHAPE,
    item: { shape, color },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`w-20 h-20 flex items-center justify-center text-6xl ${colorClasses[color]} border-2 ${
        isDragging ? 'opacity-50 scale-110' : 'opacity-100'
      } transition-transform transform duration-200 cursor-grab bg-white`}
      style={{
        borderRadius: shape === 'Circle' ? '50%' : '8px',
      }}
    >
      {shapeIcons[shape]}
    </div>
  );
};

export default DraggableShape;
